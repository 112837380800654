<template>
  <div id="round-member-tickets" class="container-fluid">
    <div class="page-header mb-1">
      <strong>รายการโพย</strong>
    </div>
    <b-card no-body class="mb-1 position-relative">
      <b-card-header header-tag="nav">
        <h5 class="card-title mb-0">
          <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะงวด</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
        </h5>
      </b-card-header>

      <b-card-body class="p-2">
        <table class="table table-bordered table-info table-hover mb-0">
          <thead>
            <tr>
              <th width="60">ลำดับ</th>
              <th class="pb-1">ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th>อัตราจ่าย</th>
              <th>เวลา</th>
              <th width="6%">รายการ</th>
              <th width="8%">ยอดบิล</th>
              <th width="8%">ส่วนลด</th>
              <th width="8%">สุทธิ</th>
              <th>สถานะ</th>
              <th>หมายเหตุ</th>
              <th width="90"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item._id">
              <td class="text-center">{{index+1}}</td>
              <td class="text-center">
                {{ item.account.mainUser.username }}
                <small v-if="showName" class="text-secondary">({{item.account.mainUser.firstName}})</small>
              </td>
              <td class="text-center">{{item.rate.rateTitle}}</td>
              <td class="text-center">
                {{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}
              </td>
              <td class="text-center">{{item.summary.length}}</td>
              <td class="text-right text-success">{{item.summary.amount | amountFormat}}</td>
              <td class="text-right text-danger">{{item.summary.discount | amountFormat}}</td>
              <td class="text-right text-success">{{item.summary.credit | amountFormat}}</td>
              <td class="text-center">
                <TicketStatus :status="item.status" />
              </td>
              <td class="text-center">{{item.remarks}}</td>
              <td class="text-left">
                <button class="btn btn-outline-info btn-sm" title="ดูรายการแทง" @click="viewTicket(item._id)"><i class="fas fa-list"></i></button>
                <button v-if="item.canCancel" class="btn btn-danger btn-sm ml-2" title="ยกเลิกโพย" @click="cancelTicket(item)"><i class="fas fa-times"></i></button>
              </td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="11" class="text-center">กรุณารอซักครู่</td>
            </tr>
          </tbody>
          <tbody v-if="!isLoading && !this.items.length">
            <tr>
              <td colspan="11" class="text-center">ไม่มีรายการโพย</td>
            </tr>
          </tbody>
        </table>
      </b-card-body>
    </b-card>

    <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />
  </div>
</template>
<script>
import RoundService from "@/services/RoundService"
import Swal from 'sweetalert2'
import _ from 'lodash'
import moment from '@/helpers/moment'
import ViewTicketModal from '@/views/setting-report/components/ViewTicketModal'
import cryptoRandomString from 'crypto-random-string'
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundMemberTickets',
  components: {
    ViewTicketModal
  },
  data() {
    return {
      data: null,
      isLoading: false,
      showName: false,
      isShowTicketModal: false,
      viewTicketId: null
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    items() {
      if(!this.data)
        return []

      return this.data.tickets.map((item)=>{
        item.canCancel = moment().isBefore(this.data.round.roundDate.close) && item.status === 'Accepted'
        return item
      })
    },
  },
  watch: {
    roundId() {
      if(this.roundId)
        this.getTickets()
    }
  },
  methods: {
    getTickets() {
      RoundService.getTickets(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    },
    cancelTicket(ticket) {
      if(!this.data)
        return

      if(ticket.status !== 'Accepted')
        return Swal.fire({
          icon: 'error',
          text: 'ไม่สามารถยกเลิกโพยได้'
        })

      if(moment().isAfter(this.data.round.roundDate.close))
        return Swal.fire({
          icon: 'error',
          title: 'หวยปิดรับแล้ว',
          text: 'ไม่สามารถยกเลิกโพยได้'
        })

      const uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      console.log(ticket)
      Swal.fire({
        title: '<span class="text-danger">ยืนยันยกเลิกโพย!</span>',
        html: `<h4>สมาชิก <span class="text-primary">${ticket.account.mainUser.username}</span><br>รายการแทง <span class="text-primary">${ticket.summary.length}</span> รายการ<br>ยอดรวม <span class="text-primary">${ticket.summary.amount}</span> บาท</h4>`,
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#dc3545',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn-danger'
        }
      })
      .then((res)=>{
        if(res.isConfirmed) {
          this.isLoading = true
          RoundService.cancelTicket(ticket._id, {uniqueCode}).then((response)=>{
            if(response.success) {
              this.$notify({
                type: 'success',
                title: 'ยกเลิกโพยแล้ว!'
              })
            }else{
              throw new Error(response?.message)
            }
          })
          .catch((e)=>{
            cAlert({
              ...e,
              title: 'ผิดพลาด!',
              text: e?.message || 'ยกเลิกโพยไม่สำเร็จ',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.isLoading = false
            this.getTickets()
          })
        }
      })
    }
  },
  mounted() {
    if(this.roundId)
      this.getTickets()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
        line-height: 1;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 85%;
      }
      th {
        padding: 5px;
        font-size: 85%;
      }
    }
  }
  tfoot {
    tr {
      td {
        font-size: 85%;
      }
    }
  }
}
</style>
